import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../components/Landing/Container'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

const { Link } = Anchor

export default function TaxYearGuidePage({ setCookieValue, data }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="tax-year">
            <SEO title="Skatterapport Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="Skatteår">
                            <Link href="#step-1" title="1. Rapport-siden" />
                            <Link href="#step-2" title="2. Opret skatteår" />
                            <Link href="#step-3" title="3. Oversigt" />
                        </Link>
                        <Link href="#rapport-section" title="Skatterapporter">
                            <Link href="#step-4" title="4. Interaktivt regneark" />
                        </Link>
                        <Link href="#finish-section" title="Færdiggør">
                            <Link href="#step-5" title="5. Lås skatteåret" />
                            <Link href="#step-6" title="6. Lås skatteåret" />
                            <Link href="#step-7" title="7. Aflever til SKAT" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Opret Skatterapporter
                        </h1>
                        <p className="landing-guide-header-p">
                            Med skatterapporter binder du al din data sammen. Det er dem der i
                            sidste ende udgør din dokumentation til Skattestyrelsen.
                            Skatterapporterne på ll33a.dk er tilpasset skatteåret. Dvs. når du
                            opretter et skatteår, "Tax Year", vil der automatisk blive genereret
                            rapporter som indeholder al relevant data for det pågældende skatteår.
                        </p>
                    </div>
                    <Divider />
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-1"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>1. Gå til rapport siden</h1>
                            <p style={{ maxWidth: 400 }}>
                                Du finder vej til dine skatterapporter fra settings-siden, herfra
                                klik på knappen "Compliance Reports"
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 1"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s1.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-2"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>2. Opret skatteår</h1>
                            <p style={{ maxWidth: 400 }}>
                                På "Create Tax Year" kan du oprette et skatteår. Et skatteår samler
                                dine udlandsophols ift. skatteåret. I eksemplet er vi ved at oprette
                                skatteåret 2021. Nederst på billedet kan man se hvilke udlandsophold
                                der er relevante for 2021. I eksemplet er det 2 udlandsophold som
                                løber:
                                <br />
                                <br />
                                1/12/2020 - 27/8/2021 og 29/8/2021 - åben slutning.
                                <br />
                                <br />
                                Eftersom at det første udlandsophold startede det foregående
                                skatteår (2020), vil en del af opholdet blive medtaget på
                                skatterapporten for 2021. Ligeledes fordi det andet udlandsophold
                                har en åben slutning så vil rapporten indeholde data fra det
                                efterfølgende skatteår (2022).
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration-big"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-3"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>3. Oversigten</h1>
                            <p style={{ maxWidth: 400 }}>
                                Fra oversigten under skatteåret kan du se de rapporter som tilhører
                                skatteåret.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 3"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="rapport-section"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Skatterapporten
                        </h1>
                        <p className="landing-guide-header-p">
                            Fra oversigten kan du gå ind på hver rapport og se samt rette i hvordan
                            din data skal præsenteres på den færdige rapport.
                        </p>
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-4"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>4. Interaktivt regneark</h1>
                            <p style={{ maxWidth: 400 }}>
                                Inde på rapporten kan du se dataene fra kalenderen i det interaktive
                                regneark. Sammen med grafen har du et godt overblik over rapporten.
                                I regnearket kan du ændre i dine Danmarksferier, bilag og noter. Vær
                                opmærksom på at disse ændringer vil blive overført til kalenderen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 4"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s4.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="finish-section"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Færdiggør skatteåret
                        </h1>
                        <p className="landing-guide-header-p">
                            Når din data er klar til at blive præsenteret for SKAT kan du låse dine
                            rapporter og dit skatteår så du ikke bliver i tvivl om hvordan rapporten
                            så ud da du afleverede den.
                        </p>
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-5"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>5. Lås rapporten</h1>
                            <p style={{ maxWidth: 400 }}>
                                Når du låser din rapport laver systemet et snapshot af din data.
                                Herefter vil ændringer fra kalenderen ikke påvirke rapporten. Du kan
                                altid fortryde og åbne rapporten igen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 5"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s5.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-6"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>6. Lås Skatteåret</h1>
                            <p style={{ maxWidth: 400 }}>
                                Efter at alle rapporter for dit skatteår er låst, der kan du låse
                                selve skatteåret. Når du låser et skatteår indikerer du at du er
                                helt færdig med din dokumentation for året, og din data er klar til
                                at blive sendt til Skattestyrelsen.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 6"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s6.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-7"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>7. Aflever skatterapporterne</h1>
                            <p style={{ maxWidth: 400 }}>
                                Det låste skatteår kan nu downloades og sendes til Skattestyrelsen.
                                Vi anbefaler at bruge Bluewhale til dette, indtil det bliver muligt
                                at aflevere direekte gennemll33a.dk.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 7"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration"
                            fluid={data.s7.childImageSharp.fluid}
                        />
                    </div>
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "guide-assets/skatterapporter-step-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "guide-assets/skatterapporter-step-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "guide-assets/skatterapporter-step-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s4: file(relativePath: { eq: "guide-assets/skatterapporter-step-4.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s5: file(relativePath: { eq: "guide-assets/skatterapporter-step-5.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s6: file(relativePath: { eq: "guide-assets/skatterapporter-step-6.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s7: file(relativePath: { eq: "guide-assets/skatterapporter-step-7.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
